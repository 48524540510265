import axios from "../services/axios";

import { toast } from "react-toastify";

const loginAPI = (basicAuth) => {
  return axios.post(
    "sessions",
    {},
    {
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Headers":
          "Origin, X-Requested-With, Content-Type, Accept",
        "Content-Type": "application/json",
        withCredentials: true,
        mode: "no-cors",
        Authorization: basicAuth,
      },
    }
  );
};

const logoutAPI = (token) => {
  return axios.delete(
    "sessions/" + token,
    {},
    {
      headers: {
        "Content-Type": "application/json",
      },
    }
  );
};

const changePassAPI = (userId, token, data) => {
  return axios.patch("layouts/CustomerChangePassword/records/" + userId, data, {
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    },
  });
};

const getInfoUser = (data, token) => {
  return axios.post("layouts/CustomerUser - Get Info/_find", data, {
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    },
  });
};

const updateCompanyAPI = (userKey, token) => {
  return axios.get(
    "layouts/TraCuuKH - API/script/UpdateCompanyList?script.param=" + userKey,
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    }
  );
};

const getData = (
  inputDate,
  inputCompany,
  inputCont,
  inputStatus,
  limit,
  offset,
  token
) => {
  try {
    const query = JSON.stringify({
      query: [
        {
          SO_CONT: inputCont,
          NGAY: inputDate,
          CONG_TY: inputCompany,
          StatusValue: inputStatus,
        },
        {
          SO_BOOKING: inputCont,
          NGAY: inputDate,
          CONG_TY: inputCompany,
          StatusValue: inputStatus,
        },
      ],
      sort: [
        {
          fieldName: "NGAY",
          sortOrder: "descend",
        },
      ],
      "script.prerequest": "UpdateDateRange",
      "script.prerequest.param": inputDate,
      limit: limit,
      offset: offset,
    });
    return axios.post("layouts/TraCuuKH - API/_find", query, {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
      maxBodyLength: Infinity,
    });
  } catch (e) {
    console.log(e);
    toast.error("Phiên truy cập hết hạn, vui lòng đăng nhập lại!.");
  }
};

const getCompanyFound = (token) => {
  return axios.get("layouts/CompanyFound/records", {
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    },
    maxBodyLength: Infinity,
  });
};

const getFile = (recordId, token) => {
  return axios.get("layouts/TraCuuKH - File/records/" + recordId, {
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    },
    maxBodyLength: Infinity,
  });
};

const getSiteShip = async () => {
  try {
    return await axios.get("/layouts/SiteShip/records", {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
      maxBodyLength: Infinity,
    });
  } catch (e) {
    console.log(e);
    toast.error("Phiên truy cập hết hạn, vui lòng đăng nhập lại!.");
  }
};

const getSaiGonNewPort = async (siteId, vesselName) => {
  const optionSiteShip = localStorage.getItem("optionSiteShip");
  const parseOption = JSON.parse(optionSiteShip);
  const filter = await parseOption.data.filter(
    (value) =>
      value.fieldData.SiteID === siteId || value.fieldData.NoiHa === siteId
  );
  let setSiteID = "GNL";
  if (filter.length > 0) {
    setSiteID = filter[0].fieldData.SiteID;
  }
  const param = JSON.stringify({
    siteId: setSiteID,
    vesselName: vesselName,
  });

  return axios.get(
    `/layouts/SiteShip/script/ePortShipsSearch?script.param=${param}`,
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
      maxBodyLength: Infinity,
    }
  );
};

export {
  loginAPI,
  logoutAPI,
  changePassAPI,
  updateCompanyAPI,
  getInfoUser,
  getData,
  getCompanyFound,
  getFile,
  getSiteShip,
  getSaiGonNewPort,
};
